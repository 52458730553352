.wrapper {
  // height: auto;

  width: 100%;
  display: flex;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  // row-gap: 40px;
  flex-direction: column;
  padding: 0px 0px 45px 0px;
  background-color: rgba(255, 255, 255, 0.06);

  .container {
    display: flex;

    justify-content: space-between;
    flex-direction: column;
    row-gap: 10px;

    .logo {
      font-size: 18px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 15px;
      font-weight: bold;
      padding: 15px 20px 5px 20px;

      img {
        max-width: 100px;
        margin-bottom: 30px;
        margin-top: 10px;
      }
    }

    .profilediv {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 0px;
      row-gap: 10px;
      cursor: pointer;

      .profile {
        width: 70px;
        height: 70px;
        max-width: 70px;
        max-height: 70px;
        border-radius: 100%;
        position: relative;
        background-color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .img2 {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          background-size: 100% 100%;
          overflow: hidden;
          object-fit: cover;
        }

        .img1 {
          width: 20px;
          height: 20px;
          text-align: center;
          -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;

          @-webkit-keyframes spin {
            0% {
              -webkit-transform: rotate(0deg);
            }

            100% {
              -webkit-transform: rotate(360deg);
            }
          }

          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }

            100% {
              transform: rotate(360deg);
            }
          }
        }

        .editprofile {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          text-align: center;
          background-color: #000000;
          width: 20px;
          height: 20px;
          position: absolute;
          right: 0;
          bottom: 0px;
          border: 2px solid #fff;
          background-color: #40a1dc;

          input {
            display: none;
          }

          .selectimage {
            width: 100%;
            height: 100%;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
              padding-bottom: 1px;

              @media screen and (max-width: 1024px) {
                position: absolute;
                top: 1px;
                right: 0px;
              }
            }
          }
        }
      }

      .showmail {
        width: 100%;
        // max-width: 12rem;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;

        .textElllip {
          white-space: nowrap;
          width: 14rem;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
        }

        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          font-size: 14px;
          color: #fff;
          opacity: 0.6;
          padding: 0 1rem;
          word-break: break-word;

          @media screen and (max-width: 1024px) {
            font-size: 10px;
          }

          &:hover {
            color: #40a1dc;
            opacity: 1;
          }
        }
      }
    }

    .heading {
      text-align: left;
      font-weight: bold;
      width: 100%;
      font-size: 16px;
      color: #fff;
    }

    .tab {
      height: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      column-gap: 15px;
      padding: 0px 25px;
      position: relative;
      // border-left: 4px solid #1144;

      .icon {
        display: block;
        display: flex;
        justify-content: center;
        width: 30px;
        color: #b2b3b9;
      }
      .imgNext {
        display: none;
      }
      .iconActive {
        display: none;
      }

      .rightIcon {
        display: none;
        position: absolute;
        right: 0;
      }

      .text {
        text-align: left;
        width: 100%;
        font-weight: normal;
        font-size: 16px;
        color: #fff;
        opacity: 0.5;

        .Qimg {
          width: 12px !important;
        }
      }

      .text2 {
        text-align: left;
        width: 100%;
        font-weight: normal;
        font-size: 16px;
        color: #fff;
        opacity: 0.5;

        .Qimg {
          width: 12px !important;
        }

        @media screen and (max-width: "600px") {
          font-size: 15px;
        }
      }

      .dashboardtext {
        display: flex;
        justify-content: space-around;
        align-items: center;

        img {
          transition: 0.7s;
        }
      }
    }

    .tab:hover {
      cursor: pointer;
      height: 40px;
      // border-left: 4px solid #020203;
      background: rgba(64, 161, 220, 0.2);
      .icon {
        display: none;
      }

      .iconActive {
        display: block;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        width: 30px;
        color: #40a1dc;
      }

      .rightIcon {
        display: block;
      }

      .text {
        opacity: 1;
      }
    }
  }

  .activeLink {
    height: 45px;
    width: 100%;
    display: flex;
    align-items: center;
    // column-gap: 15px;
    padding-left: 25px;
    position: relative;
    cursor: pointer;
    width: 100%;
    // column-gap: 15px;
    width: 90%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    // border-left: 4px solid #40a1dc;
    box-sizing: border-box;
    background: rgba(64, 161, 220, 0.2);

    .icon {
      display: none;
      color: #525357;
    }
    .imgNext {
      width: 35px;
      margin-right: 4px;
      margin-left: auto;
      display: block;
    }
    .iconActive {
      display: block;
      display: flex;
      justify-content: center;
      width: 30px;
      color: #40a1dc;
    }

    .rightIcon {
      display: block;
      position: absolute;
      right: 0;
    }

    .text {
      text-align: left;
      width: 100%;
      word-break: break-all;
      margin-left: 15px;
      font-weight: normal;
      font-size: 16px;
      color: #40a1dc;

      .Qimg {
        width: 12px !important;
        // height: 10px;
      }
    }

    .text2 {
      text-align: left;
      width: 100%;
      font-weight: normal;
      font-size: 16px;
      color: #40a1dc;

      .Qimg {
        width: 12px !important;
      }

      @media screen and (max-width: "600px") {
        font-size: 15px;
      }
    }

    .dashboardtext {
      display: flex;
      justify-content: space-around;
      align-items: center;

      img {
        transform: rotate(180deg);
        transition: 0.7s;
      }
    }
  }

  .signout {
    height: 45px;
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 15px;
    padding: 0px 32px;
    position: relative;

    .icon {
      display: block;
      display: flex;
      justify-content: center;
      width: 30px;
      color: #525357;
    }

    .iconActive {
      display: none;
    }

    .rightIcon {
      display: none;
      position: absolute;
      right: 0;
    }

    .text {
      text-align: left;
      width: 100%;
      font-weight: normal;
      font-size: 16px;
      color: #fff;
      opacity: 0.5;
    }
  }

  .signout:hover {
    cursor: pointer;
    height: 45px;
    background: linear-gradient(
      280deg,
      rgba(36, 36, 35, 0.63) 0%,
      rgba(20, 20, 20, 0.79) 47.69%,
      rgb(7, 7, 7) 100%
    );

    .icon {
      display: none;
    }

    .iconActive {
      display: block;
      display: flex;
      justify-content: center;
      width: 30px;
      color: #40a1dc;
    }

    .rightIcon {
      display: block;
    }

    .text {
      opacity: 1;
    }
  }

  .signoutActiveLink {
    height: 45px;
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 15px;
    padding: 0px 20px;
    position: relative;
    width: 100%;
    column-gap: 15px;
    background: linear-gradient(
      280deg,
      rgba(36, 36, 35, 0.63) 0%,
      rgba(20, 20, 20, 0.79) 47.69%,
      rgb(7, 7, 7) 100%
    );

    .icon {
      display: none;
    }

    .iconActive {
      display: block;
      width: 30px;
      color: #40a1dc;
    }

    .rightIcon {
      display: block;
      position: absolute;
      right: 0;
    }

    .text {
      text-align: left;
      width: 100%;
      font-weight: normal;
      font-size: 16px;
      color: #fff;
    }
  }
}
