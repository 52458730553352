.container {
  width: 100%;
  box-sizing: border-box;
  padding: 0 1.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow: hidden;
  p {
    font-size: 30px;
    font-weight: 500;
  }
  .wrapper {
    width: 100%;
    display: flex;
    column-gap: 3rem;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;

    .leftsection {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 1rem;
      padding: 2rem 0rem;
      label {
        font-weight: 600;
        font-size: 20px;
        display: flex;
        margin-bottom: 7px;

        justify-content: start;
        // text-align: start;
      }
      .playerwrapper {
        // max-width: 800px;
        // width: 600px; // Reset width
        height: auto; // Reset height
        // display: none;
      }
      span {
        font-size: 18px;
      }
    }
    img {
      width: 150px;
    }
    .rightsection {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 1rem;
      padding: 2rem 0rem;
      label {
        font-weight: 600;
        font-size: 20px;
        display: flex;
        margin-bottom: 7px;
        justify-content: start;
        // text-align: start;
      }
      span {
        font-size: 18px;
      }
      .playerwrapper {
        // max-width: 800px;
        // width: 600px; // Reset width
        height: auto; // Reset height
        // display: none;
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .container {
    padding: 0 5rem;
    p {
      font-size: 40px;
      font-weight: 700;
    }
    .wrapper {
      flex-direction: row;
    }
  }
}
@media screen and (min-width: 768px) {
  .container {
    padding: 0 5rem;
    p {
      font-size: 40px;
      font-weight: 700;
    }
    .wrapper {
      flex-direction: row;
      .leftsection {
        width: 50%;
      }
    }
  }
}
